import '../styles/footer.css';
function Footer() {
  return (
    <div className="footer"> 
    {/* prev className='app' */}
      {/* <h1>i am a footer</h1> */}
    </div>
  );
}

export default Footer;
